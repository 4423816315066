import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import Swal from "sweetalert2";
import {CompanyService} from "../../services/company.service";

@Component({
  selector: 'app-login-client',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.scss']
})
export class LoginClientComponent {
  loginForm: FormGroup;
  forgotForm: FormGroup;
  isAuthenticated: boolean;
  public loginInvalid = false;
  private formSubmitAttempt = false;
  userName: string;
  email: string;
  perms: any = [];
  listEntreprises: any = [];

  constructor(
    public translate: TranslateService,
    private companyService: CompanyService,
    private authServ: AuthService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.getAllCompnaies();
    this.loginForm = new FormGroup({
      matricule: new FormControl('', [Validators.required, Validators.pattern(/^\d{8}$/)]),
      password: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
    });
  }

  getAllCompnaies() {
    this.companyService.getCompanies().then((res: any) => {
      console.log('all compnaies: ', res);
      if (res.status) {
        this.listEntreprises = res.data.results.reverse();
      }
    });
  }

  login() {
    const controlNames = {
      matricule: 'Matricule',
      password: 'Mot de passe',
      company: 'Company',
    };
    let isFormIncomplete = true;
    for (const controlName in this.loginForm.controls) {
      const control = this.loginForm.get(controlName);
      if (control.value !== null && control.value !== '') {
        isFormIncomplete = false;
      }
      if (isFormIncomplete) {
        Swal.mixin({
          customClass: {confirmButton: 'btn btn-success'},
        }).fire({
          text: 'Tous les champs sont requis',
          icon: 'error',
          confirmButtonText: 'Close',
          reverseButtons: true,
        });
        return;
      } else if (control.errors?.required) {
        Swal.mixin({
          customClass: {confirmButton: 'btn btn-success'},
        }).fire({
          text: 'Le champ ' + controlNames[controlName] + ' est requis',
          icon: 'error',
          confirmButtonText: 'Close',
          reverseButtons: true,
        });
        return;
      }
    }
    if (this.loginForm.get(['matricule']).invalid) {
      Swal.mixin({
        customClass: {confirmButton: 'btn btn-success'},
      }).fire({
        // title: 'Are you sure?',
        text: 'Matricule invalide',
        icon: 'error',
        confirmButtonText: 'Close',
        reverseButtons: true,
      });
    } else {
      this.authServ.loginClient(this.loginForm.value).then((res: any) => {
        if (res.status) {
          this.authServ.setLocal(res.data);
          this.router.navigate(['/dashboard/client']);
        } else {
          Swal.mixin({
            customClass: {confirmButton: 'btn btn-success'},
          }).fire({
            // title: 'Are you sure?',
            text: 'Données incorrectes',
            icon: 'error',
            confirmButtonText: 'Close',
            reverseButtons: true,
          });
        }
      });
    }
  }

}
