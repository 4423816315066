import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent  implements OnInit{
  addForm:FormGroup
  langue=[
    {'url':'https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_United_Kingdom.png','lang':'English','translate':'en'},
    {'url':'https://cdn2.iconfinder.com/data/icons/world-flags-1-1/100/Tunisia-256.png','lang':'العربية','translate':'ar'},
  ]
  langSelect:any=this.langue[0]
  text_direction:string='start'

  constructor(private elementRef: ElementRef,private translateService: TranslateService) { 
    this.addForm = new FormGroup({
      statutProfessionnel: new FormControl('',),
      creditEncore: new FormControl('',),
      chequeImpaye: new FormControl('',),
      income: new FormControl('',),
      creditType: new FormControl('',),
      loanAmount: new FormControl('',),
      duration: new FormControl('',),
      amount: new FormControl('',),

    });

    translateService.addLangs(['en','ar']);
    translateService.setDefaultLang('en');
  }


selectLangue(event:any){
  console.log('eee',event)
  this.langSelect=event
  this.switchLanguage(this.langSelect.translate)
  }
  
  switchLanguage(language: string) {
    this.translateService.use(language);
    console.log('la',language);
    language=='ar'?this.text_direction='end':this.text_direction='start'
  localStorage.setItem("lang",language)
  }

  ngOnInit(): void {
    // Mobile nav toggle
    this.elementRef.nativeElement.querySelector('.mobile-nav-toggle').addEventListener('click', (e) => {
      this.toggleMobileNav();
    });

    
    // Mobile nav dropdowns activate
    const dropdownLinks = this.elementRef.nativeElement.querySelectorAll('.navbar .dropdown > a');
    dropdownLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const navbar = this.elementRef.nativeElement.querySelector('#navbar');
        if (navbar.classList.contains('navbar-mobile')) {
          e.preventDefault();
          const dropdownMenu = link.nextElementSibling;
          dropdownMenu.classList.toggle('dropdown-active');
        }
      });
    });
  }

  toggleMobileNav(): void {
    const navbar = this.elementRef.nativeElement.querySelector('#navbar');
    navbar.classList.toggle('navbar-mobile');
    const toggleIcon = this.elementRef.nativeElement.querySelector('.mobile-nav-toggle');
    toggleIcon.classList.toggle('bi-list');
    toggleIcon.classList.toggle('bi-x');
  }

  activateMobileDropdown(link: HTMLElement): void {
    const navbar = this.elementRef.nativeElement.querySelector('#navbar');
    if (navbar.classList.contains('navbar-mobile')) {
      //e.preventDefault();
      const dropdownMenu = link.nextElementSibling;
      dropdownMenu.classList.toggle('dropdown-active');
    }
  }





  calculateEligibility() {
    // Implement calculateEligibility function logic here.
  }

  onCreditTypeChange(event: Event) {
    // Implement credit type change logic here.
  }

  onDurationInputChange(event: Event) {
    // Implement duration input change logic here.
  }

  onSubmit() {
    this.calculateEligibility();
  }





  ////////////////////////////////////////////////////////////
	activeStepIndex = 0;

  steps = [
    { label: 'Personal Information' },
    { label: 'Address' },
    { label: 'Confirmation' }
  ];

  nextStep() {
    if (this.activeStepIndex < this.steps.length - 1) {
      this.activeStepIndex++;
    }
  }

  previousStep() {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }
  }

  currentStep(etape:any) {
    this.activeStepIndex=etape
  }


}



