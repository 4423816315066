import {PdfViewerModule} from 'ng2-pdf-viewer';
import {NgxPaginationModule} from 'ngx-pagination';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule,} from '@angular/core';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {Location, LocationStrategy, PathLocationStrategy,} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule,} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {MatStepperModule} from '@angular/material/stepper';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatInputModule} from '@angular/material/input';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorInterceptor} from './core/interceptor/http-error.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Page404Component} from './pages/page404/page404.component';

/* import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'; */
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';

import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';

import {ToastrModule} from 'ngx-toastr';
import {NgxDocViewerModule} from 'ngx-doc-viewer';

import {NgxStripeModule} from 'ngx-stripe';

import {SlickCarouselModule} from 'ngx-slick-carousel';

import {SimulatorComponent} from './pages/simulator/simulator.component';
import {BanklistComponent} from './pages/banklist/banklist.component';
import {MatMenuModule} from '@angular/material/menu';

import { EntrepriseListComponent } from './pages/entreprise-list/entreprise-list.component';
import { LoginClientComponent } from './pages/login-client/login-client.component';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import {ClientDashboardComponent} from "./pages/client-dashboard/client-dashboard.component";
import { ProcedureComponent } from './pages/procedure/procedure.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import { ContactNousComponent } from './pages/contact-nous/contact-nous.component';
import { HomeComponent } from './pages/home/home.component';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, Page404Component, SimulatorComponent , BanklistComponent, EntrepriseListComponent, LoginClientComponent, InscriptionComponent, ProcedureComponent, ContactNousComponent, HomeComponent],
    imports: [
        NgxDocViewerModule,
        BrowserModule,
        AppRoutingModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDropzoneModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatInputModule,
        MatRadioModule,
        MatFormFieldModule,
        SlickCarouselModule,
        Ng2SearchPipeModule,
        MatExpansionModule,
        MatCardModule,
        MatPaginatorModule,
        MatStepperModule,
        MatTabsModule,
        PdfViewerModule,
        MatTabsModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          },
          defaultLanguage: "en"
        }),

    NgxPaginationModule,
    ToastrModule.forRoot({
      disableTimeOut: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgxGalleryModule,
    NgbModule,
    NgxStripeModule.forRoot(''),
    /* AgmDirectionModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCTwmQNMKpJt6fUEN8pxvHaQlDqGfI3uV8',
            libraries: ['places'],
        }), */
    // --- translate module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatMenuModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
  ],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}