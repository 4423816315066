<div class="container-fluid p-0 pb-4 con">
    
<div class="contact-container">
    <div class="right-col">
  
      <h1 style="margin-top:0px">Contact</h1>
      <div style="margin-top: 30px ">
        <p class="content"> For efficient handling of your request, please purpose the specify</p>
        <p class="content ">لضمان دراسة فعالة لطلبك، يرجى تحديد الغرض</p>
        <p > Otherwise, you can contact us at - أو ،يمكنك التواصل معنا<br ><b><mat-icon style="height: 18px; padding-bottom: 21px;">phone</mat-icon>
          +216 42 550 600</b> / <b><mat-icon style="height: 18px; padding-bottom: 21px;">mail</mat-icon> contact@easybank.tn</b></p>
  
        <form style="margin-top: 20px" [formGroup]="form">
          <label >Subject - موضوع الرسالة</label>
          <input type="text" placeholder="Objet" formControlName="objet" required>
          <label >Message رسالة</label>
          <textarea rows="6" placeholder="Your message رسالتك" formControlName="message" required></textarea>
          <!--<a href="javascript:void(0)">--><button type="submit" class="button2" (click)="envoyer()">SEND - إرسال</button><!--</a>-->
        </form>
      </div>
    </div>
  </div>
  
  
</div>