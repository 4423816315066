
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top ">

    <div class="container d-flex align-items-center">

      <h1 class="logo me-auto"><a href="index.html">Easy <br> Bank</a></h1>
        <div class="switch-lang"> 
          <div class="current-lang"><img class="lang-flag" [src]="langSelect.url"/>
            <p class="lang-text">{{ langSelect.lang }}</p>
          </div>
          <div class="lang-dropdown" >
            <div class="selecting-lang" *ngFor="let item of langue" (click)="selectLangue(item)"><img class="lang-flag" [src]="item.url"/>
              <p class="lang-text">{{ item.lang }}</p>
            </div>
          </div>
        </div>
      <nav id="navbar" class="navbar">
        <ul>
          <li><a translate class="nav-link scrollto" href="#about">Welcome_to_our_community</a></li>
          <li><a translate class="nav-link scrollto" href="#services">our_services</a></li>
          <li><a translate class="nav-link scrollto" href="#contact">Contact_Us</a></li>
          <li><a translate class="getstarted scrollto"
              href="https://docs.google.com/forms/d/1Wrm_F-2n2hmRW06OYxigkXnyzmribo6MxfRTJE1rsps/edit?ts=65ccc486">Submit_the_Form
              </a></li>
        </ul>

        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center   pt-4 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up" data-aos-delay="200">
          <h1 translate [class]="text_direction=='start'?'text-start':'text-end'">Together_we_build_a_better_financial_future</h1>
           <h2 translate  [class]="text_direction=='start'?'text-start':'text-end'">Welcome_to_our_community</h2>
          <div class="d-flex justify-content-center justify-content-lg-start">
            <a translate href="https://docs.google.com/forms/d/1Wrm_F-2n2hmRW06OYxigkXnyzmribo6MxfRTJE1rsps/edit?ts=65ccc486"
              class="btn-get-started scrollto">
              <i class="bi bi-hand-index-thumb"></i> Submit_the_Form</a>
            <a  href="https://www.tiktok.com/@easybank.tn/video/7320660059032210693?is_from_webapp=1&sender_device=pc&web_id=7226265971303466502" class=" btn-watch-video"><i
                class="bi bi-play-circle"></i><span translate>Watch_the_video</span></a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <img src="../../../assets/assets/img/hero-img.png" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= Clients Section ======= -->
    <section id="clients" class="clients section-bg">
      <div class="container">
        <div class="row" data-aos="zoom-in">

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../../../assets/assets/img/clients/client-1.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../../../assets/assets/img/clients/client-2.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../../../assets/assets/img/clients/client-3.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../../../assets/assets/img/clients/client-4.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../../../assets/assets/img/clients/client-5.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../../../assets/assets/img/clients/client-6.png" class="img-fluid" alt="">
          </div>

        </div>

      </div>
    </section><!-- End Cliens Section -->

    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2 translate> About_us 
          </h2>
        </div>

        <div class="row content">
          <div class="col-lg-6">
            <p translate [class]="text_direction=='start'?'text-start':'text-end'">
              About_us_desc2
            </p>

          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <p translate [class]="text_direction=='start'?'text-start':'text-end'">
              About_us_desc1
            </p>
          </div>
        </div>

      </div>
    </section><!-- End About Us Section -->

    <!-- ======= Why Us Section ======= -->
    <section id="why-us" class="why-us section-bg">
      <div class="container-fluid" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

            <div class="content  text-center">
              <h3 translate><strong translate>Why_EasyBank</strong></h3>
            </div>

            <div class="accordion-list">
              <ul>
                <li>
      
                  <div id="accordion-list-1" class="collapse show text-center"  data-bs-parent=".accordion-list">
                    <p translate [class]="text_direction=='start'?'text-start':'text-end'">
                      Why_EasyBank_desc               
                    </p>
                  </div>
                </li>
              </ul>
            </div>

          </div>

          <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            style='background-image: url("../../../assets/assets/img/why-us.png");' data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
        </div>

      </div>
    </section><!-- End Why Us Section -->


    <!-- ======= Services Section ======= -->
    <section id="services" class="services section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2 translate>
            services_easybank
          </h2>
          <p translate>
            Discover_the_ease_of_banking_with_our_services
          </p>
        </div>

        <div [class]="text_direction=='start'?'row flex':'row flex flex-row-reverse'" >
          <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <div [class]="text_direction=='start'?'icon text-start':'icon text-end'"class="icon"><i [class]="text_direction=='start'?'bi bi-bank text-start':'bi bi-bank text-end'"></i></div>
              <h4 translate [class]="text_direction=='start'?'text-start':'text-end'"><a translate href="">Loan_Intermediary</a></h4>
              <p translate [class]="text_direction=='start'?'text-start':'text-end'">
                Loan_Intermediary_desc
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
            data-aos-delay="200">
            <div class="icon-box">
              <div [class]="text_direction=='start'?' icon text-start':'icon text-end'" ><i class="bi bi-pen"></i></div>
              <h4 translate [class]="text_direction=='start'?'text-start':'text-end'"><a translate href="">Negotiation</a></h4>
              <p translate [class]="text_direction=='start'?'text-start':'text-end'">
                Negotiation_desc
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in"
            data-aos-delay="300">
            <div class="icon-box">
              <div [class]="text_direction=='start'?'icon text-start':'icon text-end'" ><i class="bi bi-chat-dots"></i></div>
              <h4 translate [class]="text_direction=='start'?'text-start':'text-end'"><a translate href="">Financial_consulting</a></h4>
              <p translate [class]="text_direction=='start'?'text-start':'text-end'">
                Financial_consulting_desc
              </p>         
                
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in"
            data-aos-delay="400">
            <div class="icon-box">
              <div [class]="text_direction=='start'?'icon text-start':'icon text-end'"><i class="bi bi-card-checklist"></i></div>
              <h4 translate [class]="text_direction=='start'?'text-start':'text-end'"><a translate href="">Extra_Services</a></h4>
              <p translate [class]="text_direction=='start'?'text-start':'text-end'">Extra_Services_desc</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Services Section -->

    <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in">

        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
            <h3 translate >Submit_the_Form</h3>
            <p translate >
              Please_fill_out_the_form_so_we_can_contact_you
            </p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a translate class="cta-btn align-middle" href="https://docs.google.com/forms/d/1Wrm_F-2n2hmRW06OYxigkXnyzmribo6MxfRTJE1rsps/edit?ts=65ccc486">
              <i class="bi bi-hand-index-thumb"></i> Submit_the_Form
            </a>
                      </div>
        </div>

      </div>
    </section><!-- End Cta Section -->
  <!-- ======= Frequently Asked Questions Section ======= -->
  <section id="faq" class="faq section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2 translate>Questions_and_Answers</h2>
      </div>

      <div class="faq-list">
        <ul>
          <li data-aos="fade-up" data-aos-delay="100" [class]="text_direction=='start'?'text-start':'text-end'">
            <i class="bx bx-help-circle icon-help"></i> <a translate data-bs-toggle="collapse" class="collapse"
              data-bs-target="#faq-list-1">How_can_we_contact_you
              <i
                class="bx bx-chevron-down icon-show text-start"></i><i class="bx bx-chevron-up icon-close text-start"></i></a>
            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
              <p translate>
                How_can_we_contact_you_desc
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="200"  [class]="text_direction=='start'?'text-start':'text-end'">
            <i class="bx bx-help-circle icon-help"></i> <a translate data-bs-toggle="collapse" data-bs-target="#faq-list-2"
              class="collapsed">
              How_and_when_do_you_respond_to_my_requests
            <i
                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
              <p translate>
                How_and_when_do_you_respond_to_my_requests_desc
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300"  [class]="text_direction=='start'?'text-start':'text-end'">
            <i class="bx bx-help-circle icon-help"></i> <a translate data-bs-toggle="collapse" data-bs-target="#faq-list-3"
              class="collapsed">why_us
              <i
                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
              <p translate>
                why_us_desc 
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="500"  [class]="text_direction=='start'?'text-start':'text-end'">
            <i class="bx bx-help-circle icon-help"></i> <a translate data-bs-toggle="collapse" data-bs-target="#faq-list-5"
              class="collapsed">
              What_are_your_costs
            <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
              <p translate>
                What_are_your_costs_desc
              </p>
            </div>
          </li>

        </ul>
      </div>

    </div>
  </section><!-- End Frequently Asked Questions Section -->
    
    
     <!-- ======= Form Section ======= -->
     <!-- <section class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2> الاستمارة</h2>
          <p>
            رجاءً ملء الاستمارة بالمعلومات اللازمة باش نقدروا نتواصلوا معاكم 
            في أقرب وقت ممكن
        </div>

        <div>

          <div class="mt-lg-0 d-flex align-items-center">
            <form class="php-email-form">
              <div class="row">
                <iframe width="100%" height="680px" src="https://docs.google.com/forms/d/1Wrm_F-2n2hmRW06OYxigkXnyzmribo6MxfRTJE1rsps/edit?ts=65ccc486&embed=true" frameborder="0" marginwidth="0" marginheight="0" style="border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>          </div>

        </div>

      </div>
    </section>
    
    End Form Section -->
    
    <section class="contact">
      <div class="section-title">
        <h2 translate> loan_simulator</h2>
        <p translate>
          loan_simulator_desc
      </div>

            <div class="col-12 flex justify-content-center align-items-center" style="display: flex !important;align-items: center;justify-content: center;width: 100%;">
              <a class="btn_sum flex align-items-center justify-content-center" style="display: flex !important;align-items: center;justify-content: center; "  [routerLinkActive]="['active-link']"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/simulateur"
              target="_blank" translate>
                <i class="bi bi-hand-index-thumb pr-2"></i>              
                Loan_account
              </a>
             
            </div>
  </section>
  
<!-- ======= Team Section ======= -->

<section id="team" class="team section-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
     <h2 translate>
      EasyBank_Team
     </h2>
      <p translate>
        EasyBank_Team_desc
      </p>
    </div>

    <div class="row">

      <div class="col-lg-6">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
           <div class="pic"><img src="../../../assets/assets/img/team/mohamed.png" class="img-fluid " alt=""></div> 
          <div class="member-info">
            <h4>Mohamed khelifi
            </h4>
            <span>Chief Executive Officer</span>
            <p>محمد الخليفي
              المدير التنفيذي</p>
            <div class="social">
            
              <a  target="_blank" href="https://www.linkedin.com/in/mohamed-khelifi-3b5b58101/"> <i class="ri-linkedin-box-fill"></i> </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-4 mt-lg-0">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
         <div class="pic"><img src="../../../assets/assets/img/team/nada.png" class="img-fluid" alt=""></div>
          <div class="member-info">
            <h4> 
              Nada Ben Nahia

            </h4>
            <span>Banking Sales Officer</span>
            <p>
              
              ندى بن ناهية
              مسؤولة المبيعات البنكية            
              </p>
            <div class="social">
         
              <a  target="_blank" href="https://www.linkedin.com/in/nada-ben-nahia-41173a293/"> <i class="ri-linkedin-box-fill"></i> </a> 
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-4">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
         <div class="pic"><img src="../../../assets/assets/img/team/aziz.jpg" class="img-fluid" alt=""></div>
          <div class="member-info">
            <h4>Aziz Ben Romdhane</h4>
            <span>Chief Operating Officer</span>
            <p>عزيز بن رمضان مدير العمليات 
               </p>
            <div class="social">
         
              <a  target="_blank" href="https://www.linkedin.com/in/aziz-ben-romdhane-058663233/"> <i class="ri-linkedin-box-fill"></i> </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-4">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
        <div class="pic"><img src="../../../assets/assets/img/team/yasmin.png" class="img-fluid" alt=""></div> 
          <div class="member-info">
            <h4>Yasmine Ksiaa
            </h4>
            <span>Back-office Loan Specialist</span>
            <p>ياسمين قصيعة مسؤولة ادارة عمليات القرض
              </p>
            <div class="social">
        
              <a  target="_blank" href="https://www.linkedin.com/in/ksiaa-yasmine-4b8951173/"> <i class="ri-linkedin-box-fill"></i> </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-4">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
          <div class="pic"><img src="../../../assets/assets/img/team/skander.png" class="img-fluid" alt=""></div> 
          <div class="member-info">
            <h4>Skander Enaceur</h4>
            <span>Chief Marketing Officer</span>
            <p>إسكندر الناصر
   مدير التسويق و الاتصال</p>
              <div class="social">
            
              <a  target="_blank" href="https://www.linkedin.com/in/skander-enaceur/"> <i class="ri-linkedin-box-fill"></i> </a>
     
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-4">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
         <div class="pic"><img src="../../../assets/assets/img/team/syrine.png" class="img-fluid" alt=""></div>
          <div class="member-info">
            <h4>Sirine Smida</h4>
            <span>Customer Service Consultant</span>
            <p>
              سيرين صميدة
              مسؤولة تطوير خدمة العملاء    
            </p>
            <div class="social">
         
              <a  target="_blank" href="https://www.linkedin.com/in/sirine-smida-968673225/"> <i class="ri-linkedin-box-fill"></i> </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-4">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
         <div class="pic"><img src="../../../assets/assets/img/team/tasnim.png" class="img-fluid" alt=""></div> 
          <div class="member-info">
            <h4>Tassnim Latrach</h4>
            <span>Customer Service Consultant</span>
            <p>
              تسنيم الأطرش مكلف بالحرفاء              </p>
            <div class="social">
         
              <a   target="_blank" href="https://www.linkedin.com/in/tasnim-latrach-05a60a247"> <i class="ri-linkedin-box-fill"></i> </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mt-4">
        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
         <div class="pic"><img src="../../../assets/assets/img/team/helmi.png" class="img-fluid" alt=""></div> 
          <div class="member-info">
            <h4>Helmi Cherif
            </h4>
            <span>IT consultant</span>
            <p>
              حلمي الشريف مستشار تكنولوجيا المعلومات 
              </p>
            <div class="social">
        
              <a   target="_blank" href="https://www.linkedin.com/in/helmi-cherif-493a871b"> <i class="ri-linkedin-box-fill"></i> </a>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</section><!-- End Team Section -->


<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2 translate>Contact_Us</h2>
      <p translate>
        Contact_Us_desc        
        </p>
    </div>

    <div class="row">

      <div class="d-flex align-items-center">
        <div class="info">
          <div class="address">
            <i class="bi bi-geo-alt"></i>
            <h4 translate>Central_Office</h4>
            <p translate>
              adresse
            </p>
          </div>

          <div class="email">
            <i class="bi bi-envelope"></i>
            <h4 translate>Email</h4>
            <p>contact@easybank.tn
            </p>
          </div>

          <div class="phone">
            <i class="bi bi-phone"></i>
            <h4 translate>
              Phone        
                </h4>
            <p>+216 42 503 200 <br> +216 42 550 600</p>
          </div>
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12935.734983959268!2d10.59543!3d35.8506281!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd8a42f3d81941%3A0x91566e7064e70917!2sCyber%20Parc!5e0!3m2!1sen!2stn!4v1704579596880!5m2!1sen!2stn"
          width="100%" height="290px" style="border:0;" allowfullscreen>
        </iframe>

        </div>

      </div>

      

    </div>
  </div>
</section><!-- End Contact Section -->
  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">


    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3 translate>EasyBank</h3>
            <p translate>
              adresse <br>
              <strong translate>Phone</strong> <br>
              +216 42 503 200<br>
              +216 42 550 600
              <br>
              <strong translate>Email</strong>
               contact@easybank.tn<br>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">

          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4 translate>social_media_accounts</h4>
            <p translate>
              Connect_with_us_through_our_social_networks
            </p>
            <div class="social-links mt-3">
              <a href="https://www.facebook.com/EasyBankofficial" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.linkedin.com/company/easybank-official/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
              <a href="https://www.tiktok.com/@easybank.tn?is_from_webapp=1&sender_device=pc" class="TikTok"><i class="bx bxl-tiktok"></i></a>

            </div>
          </div>

        </div>
      </div>
    </div>
  </footer><!-- End Footer -->